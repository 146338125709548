import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DEBUG_LOGS } from '../config/constants'

import { Creators as SiteActions } from '../store/ducks/site'

import lupa from '../img/lupa.png'

import { TitleText, SearchInput, ListItem, ListContainer, SearchInputIcon } from '../components/StyledComponents'

import { colors } from '../styles'

class ListaDistribuidores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            distribuidores: []
        }
    }

    componentDidMount = () => {
        this.setInternalDistribuidores()
    }

    setInternalDistribuidores = async () => {
        const { distribuidores } = this.props.siteState;
        await this.setState({ distribuidores })
    }

    capitalize(str) {
        if (str) {
            str = str.split(" ");

            for (let i = 0, x = str.length; i < x; i++) {
                if (str && str[i] !== '') {
                    str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase()
                }
            }

            return str.join(" ");
        }
    }

    onSelectDistribuidor = (dist) => {
        this.props.asyncSetDistribuidor(dist)
    }

    renderDistribuidores = (dist) => {
        // tranformando fonte de UPPERCASE para CAMELCASE

        const nome = this.capitalize(dist.NOMEPESSOA)

        return (
            <ListItem key={dist.IDREPRESENTANTE}
                onClick={() => this.onSelectDistribuidor(dist)}>
                {nome}</ListItem>
        )
    }

    filtraDistribuidores = (e) => {
        DEBUG_LOGS && console.log(e.target.value)

        const { distribuidores } = this.props.siteState

        const listaFiltrada = distribuidores.filter(function (item) {
            return item.NOMEPESSOA.toLowerCase().search(
                e.target.value.toLowerCase()) !== -1;
        });

        this.setState({ distribuidores: listaFiltrada });
    }

    render() {
        const { nomeUsuario } = this.props.siteState
        const { distribuidores } = this.state

        return (
            <div className="col" style={styles.container}>

                <div className='row' style={styles.titleContainer}>
                    <TitleText style={styles.titleText}>Olá</TitleText>
                    <TitleText style={styles.titleText}>{nomeUsuario}!</TitleText>
                </div>

                <div className='row' style={styles.searchContainer}>
                    <SearchInputIcon>
                        <img src={lupa} alt="Logo" style={styles.icon} />
                    </SearchInputIcon>
                    <SearchInput onChange={this.filtraDistribuidores} />
                </div>

                <div className='row' style={styles.listRowContainer}>
                    <ListContainer className='col'>
                        {
                            distribuidores.map((dist) => {
                                return this.renderDistribuidores(dist)
                            })
                        }
                    </ListContainer>
                </div>

            </div>
        );
    }
}


const styles = {
    container: {
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundColor: colors.brancoFundo,
    },
    titleContainer: {
        marginTop: 60,
        alignItems: 'center',
        justifyContent: 'center',

    },
    searchContainer: {
        marginTop: 8,
        flex: 1,
        justifyContent: 'stretch',

    },
    listRowContainer: {
        marginTop: 16,
        height: '100%',
        width: '100%',
    },
    listContainer: {
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },
    titleText: {
        marginLeft: 4,
        marginRight: 4,
    },
    icon: {
        fontSize: 25,
        padding: 2,
        width: 35,
        marginLeft: 10,
        marginRight: 4,
    }
}


const mapStateToProps = state => ({
    siteState: state.siteState,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(SiteActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaDistribuidores)