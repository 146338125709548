import React, { Component } from 'react';
import { colors } from '../styles'

import { DEBUG_LOGS } from '../config/constants'

import {
    BigText,
    ViewContainer,
    FotoResponsiva,
    FotoFileContainer,
    PrimaryButton,
    ButtonText,
    MenuContainer,
    ResponseContainer,
} from './StyledComponents'

export default class FotoContainer extends Component {

    render() {
        const { foto, tipoArquivo } = this.props

        DEBUG_LOGS && console.log('tipoArquivo')
        DEBUG_LOGS && console.log(tipoArquivo)


        DEBUG_LOGS && console.log('arquivo')
        DEBUG_LOGS && console.log(foto.type)

        // verificando tipo de arquivo
        let arquivoValido = false

        // se é binario (arquivo e não foto)
        if (tipoArquivo !== 'base64') {
            if (foto.type) {
                let formato = foto.type.split('/')
                console.log(formato[0])

                if (formato[0] == 'image')
                    arquivoValido = true
            }
        } else {
            arquivoValido = true
        }



        let imagem = undefined

        console.log(arquivoValido)
        if (arquivoValido) {
            imagem = foto
            if (tipoArquivo == 'binary')
                imagem = URL.createObjectURL(foto)
        }

        return (

            <ViewContainer>
                {
                    arquivoValido
                        ? <FotoFileContainer>
                            <FotoResponsiva src={imagem} />
                            <BigText style={styles.text}>Verifique se a foto ficou nítida</BigText>
                            <MenuContainer>
                                <PrimaryButton inverter >
                                    <ButtonText rosa style={styles.butonText}
                                        onClick={this.props.onNovaFoto}>Tentar Novamente</ButtonText>
                                </PrimaryButton>
                                <PrimaryButton>
                                    <ButtonText claro
                                        onClick={this.props.onEnviaFoto}>Enviar</ButtonText>
                                </PrimaryButton>
                            </MenuContainer>
                        </FotoFileContainer>
                        : <ResponseContainer>
                            <BigText>Formato de arquivo inválido</BigText>
                            <MenuContainer>
                                <PrimaryButton inverter >
                                    <ButtonText rosa style={styles.butonText}
                                        onClick={this.props.onNovaFoto}>Tentar Novamente</ButtonText>
                                </PrimaryButton>
                            </MenuContainer>
                        </ResponseContainer>
                }

            </ViewContainer>
        );
    }
}

const styles = {
    text: {
        marginTop: 20,
        marginBottom: 0,
        paddingBottom: 0,
        fontWeight: 800,
        color: colors.rosaForte,
    },
    butonText: {
        fontWeight: 700,
    },
}