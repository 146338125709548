import React, { Component, Fragment } from 'react';
import 'react-html5-camera-photo/build/css/index.css'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { DEBUG_LOGS } from '../config/constants'

import { colors } from '../styles'


import {
    TelaFotoContainer,
    BigText,
    SmallText,
    CameraWrapper,
    MarkerSuperior,
    MarkerInferior,
    Button
} from './StyledComponents'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import upload from '../img/upload.png'

export default class FotoContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            cameraFlash: false,
            cameraPermission: false,
        }
    }

    onCameraError = (error)=> {
        DEBUG_LOGS && console.log('onCameraError', error);
    }

    handleVoltar = () => {
        this.props.onHandleVoltar()
    }

    handleOnUpload = (e) => {
        const files = Array.from(e.target.files)
        const file = files[0]

        // enviar arquivo para WS saga
        this.props.onSendFile(file)
    }

    handleFlash = () => {
        const {cameraFlash} = this.state
        DEBUG_LOGS&&console.log('status atual de: ', cameraFlash)
        
        navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'environment',
            }
        }).then((stream) => {

            if(cameraFlash === false){
                DEBUG_LOGS&&console.log('CAMERA FLASH TRUE')
                const video = document.querySelector('video');
                video.srcObject = stream;

                DEBUG_LOGS&&console.log('video')
                DEBUG_LOGS&&console.log(video)

                // get the active track of the stream
                const track = stream.getVideoTracks()[0];
                DEBUG_LOGS&&console.log('getVideoTracks()')
                DEBUG_LOGS&&console.log(stream.getVideoTracks())

                video.addEventListener('loadedmetadata', (e) => {
                    window.setTimeout(() => (
                        onCapabilitiesReady(track.getCapabilities())
                    ), 700);
                });

                function onCapabilitiesReady(capabilities) {
                    DEBUG_LOGS&&console.log('capabilities');
                    DEBUG_LOGS&&console.log(capabilities);

                    if (capabilities.torch) {
                        track.applyConstraints({
                            advanced: [{ torch: true }]
                        }).catch(e => DEBUG_LOGS&&console.log(e));

                            DEBUG_LOGS&&console.log('setando state da camera ao contrario: ', cameraFlash)
                    }
                }
            }else{
                DEBUG_LOGS&&console.log('CAMERA FLASH FALSE')                
                DEBUG_LOGS&&console.log( 'camera flash  going false!!')
                
                window.location.reload()

                //! O teste abai
                /*
                const videoElem = document.querySelector('video');

                let stream = videoElem.srcObject;
                let tracks = stream.getTracks();
                
                console.log('forEach')
                tracks.forEach(function(track) {
                    console.log(track)
                    track.stop();
                });
                
                videoElem.srcObject = stream;

                console.log('video')
                console.log(videoElem)

                // get the active track of the stream
                const track = stream.getVideoTracks()[0];
                console.log('getVideoTracks()')
                console.log(stream.getVideoTracks())

                videoElem.addEventListener('loadedmetadata', (e) => {
                    window.setTimeout(() => (
                        onCapabilitiesReady(track.getCapabilities())
                    ), 700);
                });

                function onCapabilitiesReady(capabilities) {
                    console.log('capabilities');
                    console.log(capabilities);

                    if (capabilities.torch) {
                        track.applyConstraints({
                            advanced: [{ torch: false }]
                        }).catch(e => console.log(e));

                            console.log('setando state da camera ao contrario: ', cameraFlash)
                    }
                }
                */
            
            }
            }).catch(err => DEBUG_LOGS&&console.log('getUserMedia() failed: ', err));

            this.setState({cameraFlash: !cameraFlash})
    }

    renderCamera(){
            return (   
                <Camera
                    onTakePhoto={(dataUri) => { this.props.onTakePhoto(dataUri) }}
                    onCameraError={this.onCameraError}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    imageType={IMAGE_TYPES.JPG}
                    imageCompression={0.97}
                    isFullscreen={false}
                    isMaxResolution={true}
                    isImageMirror={false}
                    isDisplayStartCameraError={true}
                    sizeFactor={1}
                    isDisplayStartCameraError={false}

                />
            )
    }

    renderCameraPermissionHelper=()=>{
        return(
            <Fragment>
                <BigText>Por favor, habilite permissão e recarregue a pagina para poder utilizar a câmera do dispositivo.</BigText>
                <Button onClick={this.askPermissionToUseCamera}>Habilitar permissão</Button>
            </Fragment>
        )
    }

    render() {

        const {cameraFlash, cameraPermission} = this.state
        const representante = this.props.representante.NOMEPESSOA


        return (
            <TelaFotoContainer>
                <div style={styles.iconContainer}>
                    <FontAwesomeIcon
                        onClick={this.handleVoltar}
                        style={styles.font} icon="arrow-circle-left" />

                    <div>
                        <label htmlFor='single'>
                            <img src={upload} alt="Logo" style={styles.icon} />
                        </label>
                        <input type='file' id='single' onChange={this.handleOnUpload} />
                    </div>

                </div>
                <div style={styles.textContainer}>
                    <BigText>Você esta na lista de depósito de</BigText>
                    <BigText>{representante}</BigText>
                    <SmallText style={styles.smallText}>Posicione o comprovante no meio do leitor.</SmallText>
                </div>
                <CameraWrapper>
                    {this.renderCamera()}
                   <MarkerSuperior />
                    <MarkerInferior />

                </CameraWrapper>
            </TelaFotoContainer>
        );
    }
}

const styles = {
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: colors.rosaForte,
        marginBottom: 6,
        marginTop: 6,
        marginLeft: 20,
        marginRight: 20,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    smallText: {
        color: colors.rosaForte,
        marginBottom: 15,
        marginTop: 17,
    },
    font: {
        fontSize: 30,

    },
    flashOff: {
        position: 'absolute',
        color: '#EAE393',
        top: 110,
        left: 27,
        fontSize: 37,
    },
    flashOn: {
        position: 'absolute',
        color: '#F9DB54',
        top: 110,
        left: 27,
        fontSize: 37,
    },
    icon: {
        width: 30,
    }
}
