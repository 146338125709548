import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Creators as SiteActions } from '../store/ducks/site'

import { colors } from '../styles'

import { FotoContainer, LoadingView, FotoReview } from '../components'
import {FotoContainerIOS} from '../components'
import { BigText, ResponseContainer, PrimaryButton, ButtonText, ImagemResponsiva } from '../components/StyledComponents'

import confirmado from '../img/confirmado.png'
import erro from '../img/erro.png'

import {isIOS} from 'react-device-detect'


class CameraTest extends Component {

    handleNovaFoto = () => {
        //console.log(this.props)
        this.props.setFotoAtual(undefined)
    }

    handleEnviaFoto = () => {
        const { tipoArquivo, fotoAtual } = this.props.siteState

        tipoArquivo == 'base64'
            ? this.handleTakePhoto(fotoAtual)
            : this.handleSendImageFile(fotoAtual)
    }

    handleNewText = (texto) => {
        this.setState({ texto })
    }

    handleExibeFoto = (fotoUri) => {
        this.props.setTipoArquivo('base64')
        this.props.asyncSetFotoAtual(fotoUri)
    }

    handleExibeArquivo = (arquivo) => {
        this.props.setTipoArquivo('binary')
        this.props.asyncSetFotoAtual(arquivo)
    }

    handleTakePhoto = async (dataUri) => {

        const imagemBase64 = dataUri

        const { distribuidorAtual } = this.props.siteState

        const usuario = distribuidorAtual.USUARIO
        const id_distribuidor = distribuidorAtual.IDREPRESENTANTE

        this.props.asyncEnviaFotoParaWs(imagemBase64, usuario, id_distribuidor)
    }

    handleSendImageFile = async (arquivo) => {
        const { distribuidorAtual } = this.props.siteState
        const usuario = distribuidorAtual.USUARIO
        const id_distribuidor = distribuidorAtual.IDREPRESENTANTE

        this.props.asyncEnviaArquivoParaWs(arquivo, usuario, id_distribuidor)
    }

    handleVoltaParaListaDistribuidores = () => {
        this.props.asyncClearDistribuidor()
    }

    renderResponse(texto) {
        return (
            <ResponseContainer >
                <ImagemResponsiva src={confirmado} alt="Logo" />
                <BigText>Pronto.</BigText>
                <BigText>{texto}</BigText>
                <PrimaryButton
                    centered
                    onClick={this.handleVoltaParaListaDistribuidores}>
                    <ButtonText claro>voltar</ButtonText>
                </PrimaryButton>
            </ResponseContainer>
        )
    }

    renderError(texto) {
        return (
            <ResponseContainer >
                <ImagemResponsiva src={erro} alt="Logo" />
                <BigText>Desculpe.</BigText>
                <BigText>{texto}</BigText>
                <PrimaryButton
                    centered
                    onClick={this.handleTentarNovamente}>
                    <ButtonText claro>voltar</ButtonText>
                </PrimaryButton>
            </ResponseContainer>
        )
    }

    handleTentarNovamente = () => {
        this.props.asyncResetaLogs()
    }

    renderLoading() {
        return (
            <ResponseContainer style={styles.loadingCol}>
                <BigText>Aguarde por favor</BigText>
                <LoadingView />
            </ResponseContainer>
        )
    }

    reset = () =>{
        this.forceUpdate()
    }

    render() {
        const { distribuidorAtual, responseMessage, loadingEnvioFoto, menssagemDeErro, fotoAtual, tipoArquivo } = this.props.siteState

        return (
            <div className="" style={loadingEnvioFoto ? styles.containerLoading : styles.container} >
                {
                    menssagemDeErro
                        ? this.renderError(menssagemDeErro)
                        : responseMessage
                            ? this.renderResponse(responseMessage)
                            : (!loadingEnvioFoto)
                                ? fotoAtual
                                    ? <FotoReview
                                        foto={fotoAtual}
                                        tipoArquivo={tipoArquivo}
                                        onNovaFoto={this.handleNovaFoto}
                                        onEnviaFoto={this.handleEnviaFoto} />
                                    : !isIOS
                                    ?<FotoContainer
                                        onTakePhoto={this.handleExibeFoto}
                                        onSendFile={this.handleExibeArquivo}
                                        representante={distribuidorAtual}
                                        onHandleVoltar={this.handleVoltaParaListaDistribuidores}
                                        reset={this.reset}
                                    />:<FotoContainerIOS
                                    onTakePhoto={this.handleExibeFoto}
                                    onSendFile={this.handleExibeArquivo}
                                    representante={distribuidorAtual}
                                    onHandleVoltar={this.handleVoltaParaListaDistribuidores}
                                    reset={this.reset}
                                />
                                : this.renderLoading()
                }
            </div>
        );
    }
}

const styles = {
    loadingCol: {
        alignSelf: 'flex-start',
    },
    containerLoading: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: colors.cinzaClaro,
    },
    container: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    responseContainer: {
        backgroundColor: '#B9F0B9',
        loadingText: '#eaeaea'
    },
    errorContainer: {
        backgroundColor: '#F2E0B8',
        color: '#874442'
    },
    imgTest: {
        width: 100,
    },
}

const mapStateToProps = state => ({
    siteState: state.siteState,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(SiteActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CameraTest)