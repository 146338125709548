import { takeLatest, all } from '@redux-saga/core/effects'

import { Types as SiteTypes } from '../ducks/site'

import {
    asyncGetDistribuidores,
    asyncSetDistribuidor,
    asyncToggleLogin,
    asyncClearDistribuidor,
    asyncEnviaFotoParaWs,
    asyncResetaLogs,
    asyncEnviaArquivoParaWs,
    asyncSetFotoAtual,
} from './siteSagas'

export default function* root() {
    yield all([
        takeLatest(SiteTypes.ASYNC_GET_DISTRIBUIDORES, asyncGetDistribuidores),
        takeLatest(SiteTypes.ASYNC_SET_DISTRIBUIDOR, asyncSetDistribuidor),
        takeLatest(SiteTypes.ASYNC_TOGGLE_LOGIN, asyncToggleLogin),
        takeLatest(SiteTypes.ASYNC_CLEAR_DISTRIBUIDOR, asyncClearDistribuidor),
        takeLatest(SiteTypes.ASYNC_ENVIA_FOTO_PARA_WS, asyncEnviaFotoParaWs),
        takeLatest(SiteTypes.ASYNC_RESETA_LOGS, asyncResetaLogs),
        takeLatest(SiteTypes.ASYNC_ENVIA_ARQUIVO_PARA_WS, asyncEnviaArquivoParaWs),


        takeLatest(SiteTypes.ASYNC_SET_FOTO_ATUAL, asyncSetFotoAtual),
    ]);
}