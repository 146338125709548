//HOST
const HOST_HOME = "localhost"
const HOST_EXTERNO_ROMANCE = 'fotosdepositos.romance.com.br'

//URL
const URL_HOME = "https://" + HOST_EXTERNO_ROMANCE + "/";

//GLOBALS
const URL = URL_HOME;
const HOST = HOST_HOME;

const DEBUG_LOGS = false

const LOGIN_SIMULADO = false

// logar-se no WS de prod?
const LOGIN_PROD = true

// enviar fotos para WS de prod
const ENVIAR_FOTOS_PARA_PROD = true

export {
    URL,
    HOST,
    DEBUG_LOGS,
    LOGIN_SIMULADO,
    LOGIN_PROD,
    ENVIAR_FOTOS_PARA_PROD
};