import {
    createActions,
    createReducer
} from 'reduxsauce'

import foto from '../../img/t1.jpg'


/**
 * ACTION TYPES & CREATORS
 */
export const {
    Types,
    Creators
} = createActions({
    asyncToggleLogin: null,
    toggleLogin: null,
    getDistribuidoresSuccess: ['distribuidores'],
    getDistribuidoresFailure: ['errorMessage'],
    asyncGetDistribuidores: ['usuario', 'senha'],
    setDistribuidor: ['distribuidor'],
    asyncSetDistribuidor: ['distribuidor'],
    setUsuario: ['usuario', 'nomePessoa'],
    asyncClearDistribuidor: null,
    clearDistribuidor: null,

    asyncEnviaFotoParaWs: ['imagemBase64', 'usuario', 'id_distribuidor'],
    enviaFotoParaWsSuccess: null,
    enviaFotoParaWsFailure: ['menssagemDeErro'],

    resetaLogs: null,
    asyncResetaLogs: null,

    asyncEnviaArquivoParaWs: ['arquivo', 'usuario', 'id_distribuidor'],

    asyncSetFotoAtual: ['foto'],
    setFotoAtual: ['foto'],
    setTipoArquivo: ['tipo'],
})

const distribuidores = [{
        "IDPESSOA": 279432,
        "IDREPRESENTANTE": 49402,
        "NOMEPESSOA": "MARIA JOANA NUNES",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 211529,
        "IDREPRESENTANTE": 49427,
        "NOMEPESSOA": "Joaquin Pereira Silva",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 225,
        "IDREPRESENTANTE": 2079,
        "NOMEPESSOA": "Manuel Ferreira Souza",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 34466,
        "IDREPRESENTANTE": 2583,
        "NOMEPESSOA": "Edimilson Nunes",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 241,
        "IDREPRESENTANTE": 2148,
        "NOMEPESSOA": "Aliceia Negiver",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 193900,
        "IDREPRESENTANTE": 5204,
        "NOMEPESSOA": "Pedro Boh",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 66414,
        "IDREPRESENTANTE": 5209,
        "NOMEPESSOA": "Mazah Nunes",
        "USUARIO": "MARIA JOANA NUNES"
    }, {
        "IDPESSOA": 165340,
        "IDREPRESENTANTE": 49515,
        "NOMEPESSOA": "Maria Joana Pessoa",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 55350,
        "IDREPRESENTANTE": 4028,
        "NOMEPESSOA": "Maria Joana Pessoa",
        "USUARIO": "MARIA JOANA NUNES"
    },
    {
        "IDPESSOA": 343474,
        "IDREPRESENTANTE": 49518,
        "NOMEPESSOA": "Joana Pessoa",
        "USUARIO": "MARIA JOANA NUNES"
    },
]


/**
 * STATE INICIAL
 */
const INITIAL_STATE = {
    responseMessage: '',
    menssagemDeErro: '',
    loggedIn: false,
    usuario: '',
    loading: false,
    loadingEnvioFoto: false,
    distribuidorAtual: undefined,
    nomeUsuario: undefined,
    distribuidores: undefined,
    fotoAtual: undefined,
    tipoArquivo: undefined,
}

/**
 * REDUCER HANDLERS
 */
const toggleLogin = (state = INITIAL_STATE) => {
    return {
        ...state,
        loggedIn: !state.loggedIn,
        distribuidorAtual: undefined,
        fotoAtual: undefined,
        tipoArquivo: undefined
    }
}

const asyncGetDistribuidores = (state = INITIAL_STATE) => {
    return {
        ...state,
        distribuidores: [],
        loading: true
    }
}

const getDistribuidoresSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        distribuidores: action.distribuidores,
        loading: false,
        menssagemDeErro: '',
        loggedIn: true
    }
}

const getDistribuidoresFailure = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        distribuidores: [],
        loading: false,
        menssagemDeErro: action.errorMessage
    }
}

const setDistribuidor = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        distribuidorAtual: {
            IDPESSOA: action.distribuidor.IDPESSOA,
            IDREPRESENTANTE: action.distribuidor.IDREPRESENTANTE,
            USUARIO: action.distribuidor.USUARIO,
            NOMEPESSOA: action.distribuidor.NOMEPESSOA
        }
    }
}

const setUsuario = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        usuario: action.usuario,
        nomeUsuario: action.nomePessoa
    }
}

const clearDistribuidor = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        distribuidorAtual: undefined,
        menssagemDeErro: '',
        responseMessage: ''
    }
}

const asyncEnviaFotoParaWs = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEnvioFoto: true,
        menssagemDeErro: '',
        responseMessage: ''
    }
}

const enviaFotoParaWsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEnvioFoto: false,
        responseMessage: 'Envio realizado com sucesso!',
        menssagemDeErro: '',
        fotoAtual: undefined,
        tipoArquivo: undefined
    }
}

const enviaFotoParaWsFailure = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEnvioFoto: false,
        menssagemDeErro: action.menssagemDeErro,
        responseMessage: '',
        fotoAtual: undefined,
        tipoArquivo: undefined
    }
}

const resetaLogs = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        menssagemDeErro: '',
        responseMessage: ''
    }
}

const asyncEnviaArquivoParaWs = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingEnvioFoto: true,
        menssagemDeErro: '',
        responseMessage: ''
    }
}

const asyncSetFotoAtual = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loadingFotoAtual: true,
    }
}

const setFotoAtual = (state = INITIAL_STATE, action) => {

    return {
        ...state,
        loadingFotoAtual: false,
        fotoAtual: action.foto
    }
}

const setTipoArquivo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        tipoArquivo: action.tipo
    }
}

/**
 * REDUCER
 */
export default createReducer(INITIAL_STATE, {
    [Types.TOGGLE_LOGIN]: toggleLogin,
    [Types.GET_DISTRIBUIDORES_SUCCESS]: getDistribuidoresSuccess,
    [Types.GET_DISTRIBUIDORES_FAILURE]: getDistribuidoresFailure,
    [Types.ASYNC_GET_DISTRIBUIDORES]: asyncGetDistribuidores,
    [Types.SET_DISTRIBUIDOR]: setDistribuidor,
    [Types.SET_USUARIO]: setUsuario,
    [Types.CLEAR_DISTRIBUIDOR]: clearDistribuidor,
    [Types.ASYNC_ENVIA_FOTO_PARA_WS]: asyncEnviaFotoParaWs,
    [Types.ENVIA_FOTO_PARA_WS_SUCCESS]: enviaFotoParaWsSuccess,
    [Types.ENVIA_FOTO_PARA_WS_FAILURE]: enviaFotoParaWsFailure,
    [Types.RESETA_LOGS]: resetaLogs,

    [Types.ASYNC_ENVIA_ARQUIVO_PARA_WS]: asyncEnviaArquivoParaWs,


    [Types.ASYNC_SET_FOTO_ATUAL]: asyncSetFotoAtual,
    [Types.SET_FOTO_ATUAL]: setFotoAtual,

    [Types.SET_TIPO_ARQUIVO]: setTipoArquivo,
})