const colors = {

    //* LAYOUT NOVO
    branco: '#FFFFFF',
    brancoFundo: '#FCFCFE',
    cinzaClaro: '#EDEDED',
    cinzaMenosClaro: '#EDEDED',
    cinzaClaroFundo: '#F5F5F5',
    cinza: '#868688',
    cinzaEscuro: '#6E6E6C',
    preto: '#6E6E6C',
    pretoPreto: '#080808',
    rosaClaro: '#FBD8E6',
    rosaMaisClaro: '#FFEFF5',
    rosaForte: '#D4407C',

    //* CUSTOMIZADAS
    danger: '#d9534f',
    success: '#5cb85c',


    //* ANTIGAS
    roxoEscuro: '#6E008C',
    rosaShock: '#FA9DD5',
    //rosaForte: '#FA007F',
    roxoFraco: '#DBC7D8',
    //branco: '#fff',
    roxoMaisEscuro: '#8E7C93',
    roxoMenosEscuro: '#D0A5C0',
    //rosaClaro: '#F6C0D0',
    cinzaQuaseRoxo: '#485665',
    //cinzaEscuro: '#1E3231',
    //preto: '#000',
    palletaRomanceComplementar: {
        rosa1: '#992050',
        rosa2: '#E64786',
        rosa3: '#FF69A5',
    },
    palletaRomanceMonocromatico: {
        rosa1: '#663E4E',
        rosa2: '#E64786',
        rosa3: '#EA8FB3',
        rosa4: '#B33769',
        rosa5: '#66203C',
    },
    palletaRomanceSombras: {
        rosa1: '#A63361',
        rosa2: '#66203C',
        rosa3: '#E64786',
        rosa4: '#F24B8E',
        rosa5: '#CC3F78',
    },


}

export default colors;