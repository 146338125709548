import React, { Component } from 'react';

import { LoadingSpinner } from '../components/StyledComponents'

export default class LoadingView extends Component {
    render() {
        return (
            <div className="row" style={styles.container}>
                <div style={styles.loadingSpinner}>
                    <LoadingSpinner />
                </div>
            </div >
        );
    }
}

const styles = {
    container: {
        flex: 1,
        alignSelf: 'stretch',
        justifyContent: 'center',
        marginTop: 50,
        marginLeft: 20,
    },
}