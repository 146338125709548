import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators as SiteActions } from '../store/ducks/site'

import { colors } from '../styles'

import { LoadingView } from '../components'

import {
    TextInput,
    BigText,
    SmallText,
    Button,
} from '../components/StyledComponents'

import logo from '../img/logo.png'


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: '',
            senha: '',
            alertMessage: ''
        }
    }

    componentDidMount = () => {

        const SessionUsuario = sessionStorage.getItem('@EnviaFotoComprovante:usuario');
        const SessionSenha = sessionStorage.getItem('@EnviaFotoComprovante:senha');

        if (SessionUsuario && SessionSenha)
            this.props.asyncGetDistribuidores(SessionUsuario, SessionSenha)
    }

    componentDidUpdate = () => {
        //console.log(this.props.siteState)
    }

    handleLogin = () => {
        const { usuario, senha } = this.state

        if (usuario === '') {
            this.setState({
                alertMessage: 'Por favor preencha o campo login acima.'
            })

        } else if (senha === '') {
            this.setState({
                alertMessage: 'Por favor preencha o campo senha acima.'
            })

        } else {
            // Valida usuario no WS
            this.setState({
                alertMessage: ''
            })

            this.props.asyncGetDistribuidores(usuario, senha)
        }
    }

    handleUsuarioChange = (event) => {
        this.setState({ usuario: event.target.value });
    }

    handleSenhaChange = (event) => {
        this.setState({ senha: event.target.value })
    }

    // Efetua login ao pressionar "ENTER"
    onEnterKeyDown = (e) => {
        if (e.key === "Enter") {
            this.handleLogin()
        }
    }

    render() {
        const { alertMessage, usuario, senha } = this.state
        const { loading, menssagemDeErro } = this.props.siteState

        return (
            <div
                className='col'
                style={styles.container}
                onKeyDown={this.onEnterKeyDown}>

                <div className='row' style={styles.logoContainer}>
                    <div className='col'>
                        <div className='row' style={styles.logoIconBox}>
                            <img src={logo} alt="Logo" style={styles.icon} />
                        </div>
                        <div className='row' style={styles.logoTextBox}>
                            <div className='col'>
                                <BigText>Bem vindo (a) ao</BigText>
                                <BigText>comprovante de depositos</BigText>
                                <BigText >Romance.</BigText>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !loading
                        ? <div className='row' style={styles.formContainer}>
                            <div className='col' style={styles.formInputContainer}>
                                <TextInput
                                    type="text"
                                    placeholder="Login"
                                    value={usuario}
                                    onChange={this.handleUsuarioChange} />
                                <TextInput
                                    type="password"
                                    placeholder="Senha"
                                    value={senha}
                                    onChange={this.handleSenhaChange} />
                                {alertMessage && <SmallText style={styles.heplerText}>{alertMessage}</SmallText>}
                                {menssagemDeErro && <SmallText style={styles.heplerText}>{menssagemDeErro}</SmallText>}
                                <Button onClick={this.handleLogin}>
                                    Entrar
                                </Button>
                            </div>
                        </div>
                        : <LoadingView />
                }
            </div>
        );
    }
}


const styles = {
    container: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundColor: colors.branco,
    },
    logoContainer: {
        marginBottom: 10,
    },
    logoIconBox: {
        color: colors.rosaForte,
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginBottom: 8,
    },
    icon: {
        marginTop: 50,
        width: 50,
    },
    logoTextBox: {
        alignItems: 'flex-start',
    },
    formContainer: {
        width: '100%',
        justifyContent: 'center',

    },
    formInputContainer: {
        justifyContent: 'flex-end',
        marginTop: 10,
        maxWidth: 500,
    },
    heplerText: {
        color: colors.danger,
        alignSelf: 'flex-start',
        marginLeft: 40,
        marginTop: 0,
        marginBottom: 10,
        fontWeight: 300,
    }
}


const mapStateToProps = state => ({
    siteState: state.siteState,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(SiteActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home)