import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'


// importando reducers
import rootSaga from './sagas';
import reducers from './ducks';

// instanciando o SAGA-MIDDLEWARE
const sagaMiddleware = createSagaMiddleware();

// middlewares
const middleware = applyMiddleware(sagaMiddleware);

// instanciando a STORE
const store = createStore(reducers, compose(middleware))

// executando o SAGA-MIDDLEWARE
sagaMiddleware.run(rootSaga)

export default store