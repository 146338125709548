/**
 * Conjunto de ferramentas para auxiliar na manipulação de strings
 */


// retorna primeiro nome
function retornaPrimeiroNome(nomeCompleto) {

    // cria array com nome e sobrenomes
    let primeiroNome = nomeCompleto.split(" ")

    // capitaliza nomes
    for (let i = 0, x = primeiroNome.length; i < x; i++) {
        if (primeiroNome && primeiroNome[i] !== '') {
            primeiroNome[i] = primeiroNome[i][0].toUpperCase() + primeiroNome[i].substr(1).toLowerCase();
        }
    }

    // retorna apenas o primeiro nome
    return primeiroNome[0]
}

export {
    retornaPrimeiroNome,
}