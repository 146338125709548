import React, { Component } from 'react';

import { BigText } from '../components/StyledComponents'
import { colors } from '../styles';

export default class TelaAcessadaNoPC extends Component {
    render() {
        return (
            <div className='col' style={styles.container}>
                <BigText style={styles.text}>
                    Desculpe, este site foi desenvolvido com foco em dispositivos móveis.</BigText>
                <BigText style={styles.text}>Tente novamente em um tablet ou celular.</BigText>
            </div>
        );
    }
}


const styles = {
    container: {
        paddingTop: 100,
    },
    text: {
        margin: 10,
        fontWeight: 700,
        fontSize: 23,
        color: colors.rosaShock,
    }
}
