import React, { Component, Fragment } from 'react';
import 'react-html5-camera-photo/build/css/index.css'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { DEBUG_LOGS } from '../config/constants'

import { colors } from '../styles'

import {isAndroid} from 'react-device-detect'

import {
    TelaFotoContainer,
    BigText,
    SmallText,
    CameraWrapper,
    MarkerSuperior,
    MarkerInferior,
    Button,
} from './StyledComponents'

import upload from '../img/upload.png'
import acesa_sem_raios from '../img/acesa_sem_raios.png'
import lampada_acesa from '../img/lampada_acesa.png'
import seta from '../img/seta.png'

export default class FotoContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            cameraFlash: false,
            cameraPermission: false,
            cameraPermissionError: false,
            cameraLoading: true,
        }
    }

    componentDidMount=()=>{
        console.log('componentDidMount')
        navigator.permissions.query({name:'camera'}).then((result) => {
            console.log('navigator.permissions.query', result)

            // PERMISSÃO GARANTIDA
            if (result.state == 'granted') {
                DEBUG_LOGS &&console.log('tem permissao de camera')
                this.setState({cameraPermission: true})

            // PERMISSÃO NÃO DEFINIDA
            } else if (result.state == 'prompt') {
                DEBUG_LOGS &&console.log('nenhuma permissao de camera definida')
                this.setState({cameraPermission: false})

            // PERMISSÃO NEGADA
            }else if(result.state == 'denied'){
                DEBUG_LOGS &&console.log('permissao de acesso a camera negada')
                this.setState({cameraPermissionError: true})
            }
        }).catch((err)=>{
            console.log('err')
            console.log(err)
            this.setState({cameraPermissionError: true})
        })
    }

    askPermissionToUseCamera=()=>{
        DEBUG_LOGS&&console.log('askPermissionToUseCamera')
        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((res)=>{
            DEBUG_LOGS&&console.log('getUserMedia')
            DEBUG_LOGS&&console.log(res)
            window.location.reload()
        }).catch((err)=>{
            console.log('askPermissionToUseCamera [error]:')
            console.log(err)
            this.setState({cameraPermissionError: true})
        })
    }

    onCameraError = (error)=> {
        DEBUG_LOGS && console.log('onCameraError', error);
    }

    handleVoltar = () => {
        this.props.onHandleVoltar()
    }

    handleOnUpload = (e) => {
        const files = Array.from(e.target.files)
        const file = files[0]

        // enviar arquivo para WS saga
        this.props.onSendFile(file)
    }

    handleFlash = () => {
        const {cameraFlash} = this.state
        DEBUG_LOGS&&console.log('status atual de: ', cameraFlash)
        
        navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'environment',
            }
        }).then((stream) => {

            if(cameraFlash === false){
                DEBUG_LOGS&&console.log('CAMERA FLASH TRUE')
                const video = document.querySelector('video');
                video.srcObject = stream;

                DEBUG_LOGS&&console.log('video')
                DEBUG_LOGS&&console.log(video)

                // get the active track of the stream
                const track = stream.getVideoTracks()[0];
                DEBUG_LOGS&&console.log('getVideoTracks()')
                DEBUG_LOGS&&console.log(stream.getVideoTracks())

                video.addEventListener('loadedmetadata', (e) => {
                    window.setTimeout(() => (
                        onCapabilitiesReady(track.getCapabilities())
                    ), 700);
                });

                function onCapabilitiesReady(capabilities) {
                    DEBUG_LOGS&&console.log('capabilities');
                    DEBUG_LOGS&&console.log(capabilities);

                    if (capabilities.torch) {
                        track.applyConstraints({
                            advanced: [{ torch: true }]
                        }).catch(e => DEBUG_LOGS&&console.log(e));

                            DEBUG_LOGS&&console.log('setando state da camera ao contrario: ', cameraFlash)
                    }
                }
            }else{
                DEBUG_LOGS&&console.log('CAMERA FLASH FALSE')                
                DEBUG_LOGS&&console.log( 'camera flash  going false!!')
                
                window.location.reload()

                //! O teste abai
                /*
                const videoElem = document.querySelector('video');

                let stream = videoElem.srcObject;
                let tracks = stream.getTracks();
                
                console.log('forEach')
                tracks.forEach(function(track) {
                    console.log(track)
                    track.stop();
                });
                
                videoElem.srcObject = stream;

                console.log('video')
                console.log(videoElem)

                // get the active track of the stream
                const track = stream.getVideoTracks()[0];
                console.log('getVideoTracks()')
                console.log(stream.getVideoTracks())

                videoElem.addEventListener('loadedmetadata', (e) => {
                    window.setTimeout(() => (
                        onCapabilitiesReady(track.getCapabilities())
                    ), 700);
                });

                function onCapabilitiesReady(capabilities) {
                    console.log('capabilities');
                    console.log(capabilities);

                    if (capabilities.torch) {
                        track.applyConstraints({
                            advanced: [{ torch: false }]
                        }).catch(e => console.log(e));

                            console.log('setando state da camera ao contrario: ', cameraFlash)
                    }
                }
                */
            
            }
            }).catch(err => DEBUG_LOGS&&console.log('getUserMedia() failed: ', err));

            this.setState({cameraFlash: !cameraFlash})
    }

    onCameraStart = () =>{
        console.log('started camera')
        this.setState({cameraLoading:false})
    }

    renderCamera(){

            return (   
                <Camera
                    onCameraStart={this.onCameraStart}
                    onTakePhoto={(dataUri) => { this.props.onTakePhoto(dataUri) }}
                    onCameraError={this.onCameraError}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    imageType={IMAGE_TYPES.JPG}
                    imageCompression={0.97}
                    isFullscreen={false}
                    isMaxResolution={true}
                    isImageMirror={false}
                    isDisplayStartCameraError={true}
                    sizeFactor={1}
                    isDisplayStartCameraError={false}
                />
            )
    }

    renderCameraPermissionHelper=()=>{
        
        if(!this.state.cameraPermissionError){
            return(
                <Fragment>
                    <SmallText  style={styles.smallText}>Para continuar, precisamos de sua permissão para utilizar a câmera do dispositivo.</SmallText>
                    <Button style={{textAlign:'center'}} onClick={this.askPermissionToUseCamera}>Habilitar permissão</Button>
                </Fragment>
            )
        }else{
            return(
                <Fragment>
                    <BigText style={{marginTop:40, marginBottom:20}}>Desculpe, mas parece que seu navegador está bloqueando acesso a câmera.</BigText>
                    <SmallText  style={styles.smallText}>Para continuar, configure a permissão de acesso a câmera manualmente em seu navegador e recarregue a página.</SmallText>
                </Fragment>
            )
        }
    }

    render() {
        const {cameraFlash, cameraPermission, cameraLoading} = this.state
        const representante = this.props.representante.NOMEPESSOA

        console.log('cameraPermission')
        console.log(cameraPermission)

        return (
            <TelaFotoContainer>
                <div style={styles.iconContainer}>
                        <div>
                            <img src={seta}
                                onClick={this.handleVoltar}
                                alt="Logo" style={styles.iconSeta} />
                        </div>
                        <div>
                         {
                            cameraPermission && isAndroid && cameraFlash
                            ? <img src={lampada_acesa} className="switch"
                            onClick={this.handleFlash}
                            alt="Logo" style={styles.iconLampada} />
                            : <img src={acesa_sem_raios} className="switch"
                            onClick={this.handleFlash}
                            alt="Logo" style={styles.iconLampada} />
                        }
                        </div>
                    <div>
                        <label htmlFor='single'>
                            <img src={upload} alt="Logo" style={styles.icon} />
                        </label>
                        <input type='file' id='single' onChange={this.handleOnUpload} />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    {cameraPermission&&<BigText>Você esta na lista de depósito de</BigText>}
                    {cameraPermission&&<BigText>{representante}</BigText>}
                    {cameraPermission&&<SmallText style={styles.smallText}>Posicione o comprovante no meio do leitor.</SmallText>}
                </div>
                <CameraWrapper style={cameraLoading ?styles.cameraWrapper: styles.cameraWrapperVisible }>
                    {cameraPermission&&this.renderCamera()}
                    {cameraPermission&&<MarkerSuperior />}
                    {cameraPermission&&<MarkerInferior />}
                </CameraWrapper>
                {!cameraPermission&&this.renderCameraPermissionHelper()}
            </TelaFotoContainer>
        );
    }
}

const styles = {
    cameraWrapper:{
        display:'none'
    },
    cameraWrapperVisible:{
        display:'flex'
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: colors.rosaForte,
        marginBottom: 32,
        marginTop: 6,
        marginLeft: 20,
        marginRight: 20,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    smallText: {
        color: colors.rosaForte,
        marginBottom: 15,
        marginTop: 17,
    },
    iconSeta: {
        marginTop: 10,
        width: 37,
        marginTop: 2,
    },
    icon: {
        width: 30,
    },
    iconLampada: {
        width: 37,
        marginTop:-11,
    }
    
}
