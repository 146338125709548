import store from './store'
import React, { Component } from 'react'
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { faArrowCircleLeft, faSdCard, faCompressArrowsAlt, faArrowsAlt, faSearch, faLightbulb } from '@fortawesome/free-solid-svg-icons'

import RootPage from './pages/RootPage';

library.add(fas, faArrowCircleLeft, faSdCard, faSearch, faCompressArrowsAlt, faArrowsAlt, faLightbulb)


class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <RootPage />
            </Provider>
        );
    }
}

export default App;
