import React from 'react';
import styled from 'styled-components';

import { colors } from '../styles'

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

/*
inner-circle
left: 50%;
    top: 38px;
    height: 44px;
    width: 44px;
    background: #fff;
    margin: -22px 0 0 -22px;
    z-index: 2;


    outer-circle
        left: -37px;
    height: 75px;
    width: 75px;
    background-color: hsla(0,0%,100%,.4);
    z-index: 1;
*/

const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};

// top right bottom left

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const ImagemResponsiva = styled.img`

    width: 100px;
    align-self: center;
    margin-bottom: 20px;

    @media  ${device.mobileS} {
        width: 100px;
    }

    @media  ${device.mobileM} { 
        width: 130px;
    }

    @media  ${device.mobileL} { 
        width: 150px;
    }
`

const ViewContainer = styled.div`

    margin: 0;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
`

const FotoResponsiva = ({ src }) => (
    <Foto src={src} />
)


const Foto = styled.img`
    width: 100%;
    max-height: 60vh;
    object-fit: scale-down;
`

const FotoFileContainer = styled.div`
    background-color: ${colors.branco};

    -webkit-box-shadow: -3px 3px 13px 3px rgba(0,0,0,0.2);
    -moz-box-shadow: -3px 3px 13px 3px rgba(0,0,0,0.2);
    box-shadow: -2px 5px 13px 2px rgba(0,0,0,0.2);

    margin: 20px;
    margin-top: 15px;
    margin-bottom: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0px;
    padding-top: 10px;
    padding-bottom: 6px;
`


const ResponseContainer = styled.div`
    -webkit-box-shadow: -3px 3px 13px 3px rgba(0,0,0,0.06);
    -moz-box-shadow: -3px 3px 13px 3px rgba(0,0,0,0.06);
    box-shadow: -2px 5px 13px 2px rgba(0,0,0,0.06);

    background-color: ${colors.branco};
    
    display: flex;
    flex-direction: column;
    
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 18px;

    margin-top: 30px;

    @media  ${device.mobileS} {
        padding: 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media  ${device.mobileM} { 
        padding: 20px;
        padding-top: 33px;
        padding-bottom: 33px;
    }

    @media  ${device.mobileL} { 
        padding: 40px;
        padding-top: 35px;
        padding-bottom: 35px;
    }
`

const LoadingSpinner = () => (
    <StyledSpinner viewBox="0 0 50 50">
        <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
        />
    </StyledSpinner>
)

const StyledSpinner = styled.svg`
animation: rotate 2s linear infinite;
margin: -25px 0 0 -25px;
width: 50px;
height: 50px;

& .path {
  stroke: ${colors.rosaShock};
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}`

const tamanhoDoMarker = 35
const larguraDoMarker = 3
const marginDoMarker = 23

const MarkerSuperior = styled.span`
    border-top: solid ${larguraDoMarker * 0.5}px ${colors.rosaForte};
    border-left: solid ${larguraDoMarker * 0.5}px ${colors.rosaForte};

    position: absolute;
    top: 0;
    left: 0;

    margin: ${marginDoMarker * 0.4}px;

    width: ${tamanhoDoMarker * 0.6}px;
    height: ${tamanhoDoMarker * 0.6}px;

    @media  ${device.mobileS} {
        width: ${tamanhoDoMarker * 0.8}px;
        height: ${tamanhoDoMarker * 0.8}px;
        margin:  ${marginDoMarker * 0.6}px;
    }

    @media  ${device.mobileM} { 
        width: ${tamanhoDoMarker * 0.9}px;
        height: ${tamanhoDoMarker * 0.9}px;
        margin:  ${marginDoMarker * 0.8}px;
    }

    @media  ${device.mobileL} { 
        width: ${tamanhoDoMarker}px;
        height: ${tamanhoDoMarker}px;
    }
`;

const MarkerInferior = styled.span`
    border-bottom: solid ${larguraDoMarker * 0.5}px ${colors.rosaForte};
    border-right: solid ${larguraDoMarker * 0.5}px ${colors.rosaForte};

    position: absolute;
    bottom: 0;
    right: 0;

    margin:  ${marginDoMarker * 0.4}px;
    margin-bottom: ${(marginDoMarker * 0.4) + 11}px;

    width: ${tamanhoDoMarker * 0.6}px;
    height: ${tamanhoDoMarker * 0.6}px;

    @media  ${device.mobileS} {
        width: ${tamanhoDoMarker * 0.8}px;
        height: ${tamanhoDoMarker * 0.8}px;
        margin:  ${marginDoMarker * 0.6}px;
        margin-bottom: ${(marginDoMarker * 0.4) + 11}px;
    }

    @media  ${device.mobileM} { 
        width: ${tamanhoDoMarker * 0.9}px;
        height: ${tamanhoDoMarker * 0.9}px;
        margin:  ${marginDoMarker * 0.8}px;
        margin-bottom: ${(marginDoMarker * 0.4) + 11}px;
    }

    @media  ${device.mobileS} { 
        width: ${tamanhoDoMarker}px;
        height: ${tamanhoDoMarker}px;
        margin-bottom: ${(marginDoMarker * 0.4) + 11}px;
    }
`;

const CameraWrapper = styled.div`
    align-self: center;
    margin: 0px ;
    padding: 0px ;
    position: relative ;
`

const TelaFotoContainer = styled.div`

    margin: 10px 0px 40px 0px;
    
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    align-content:center;

    @media  ${device.mobileS} {
        margin: 15px 15px 0px 15px;
    }

    @media  ${device.mobileM} { 
        margin: 25px 25px 0px 25px;
    }

    @media  ${device.mobileS} { 
        margin: 40px 40px 0px 40px;
    }
`

const ListContainer = styled.div`
    -webkit-box-shadow: -3px 3px 13px 3px rgba(0,0,0,0.10);
    -moz-box-shadow: -3px 3px 13px 3px rgba(0,0,0,0.10);
    box-shadow: -2px 5px 13px 2px rgba(0,0,0,0.10);

    border-radius: 4px;
    align-items: strech;
    justify-content: flex-start;

    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    ::-webkit-scrollbar:vertical {
        width: 8px;
    }
    
    ::-webkit-scrollbar:horizontal {
        height: 6px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 10px;
        border: 2px solid #ffffff;
    }
    
    ::-webkit-scrollbar-track {
        border-radius: 10px; 
        background-color: #ffffff; 
    }
    

    height: 80%;

    margin-left: 6px;
    margin-right: 6px;
    margin-top: 0px;    

    @media  ${device.mobileS} {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px; 
    }

    @media  ${device.tablet} {
        height: 100%;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px; 
    }
`

const ListItem = styled.a`

    font-family: 'MontserratRegular';
    text-align: flex-start;
    font-size: 14px;

    padding: 10px 10px;
    margin: 0px;
    padding-left: 20px;

    background-color: ${colors.branco};
    color: ${colors.cinza}

    border-bottom: solid 2px rgba(0,0,0,0.10);
    border-radius: 4px;
    
    @media  ${device.mobileS} {
        font-size: 16px;
        padding: 12px 12px;
        padding-left: 35px;
    }

    @media  ${device.mobileM} {
        font-size: 20px;
        padding: 17px 17px;
        padding-left: 40px;
    }
`;

const SearchInputIcon = styled.span`
    border: none;
    background: ${colors.cinzaClaro};
    color: ${colors.cinzaEscuro}
    line-height: 35px;

    margin-top: 6px;
    margin-bottom: 6px;

    margin-left: 6px;

    border-radius: 5px 0px 0px 5px;
    padding-left: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;

    @media  ${device.mobileS} {
        line-height: 42px;
        margin-left: 20px;

    }

    @media  ${device.mobileM} {
        line-height: 50px;

        margin-top: 7px;
        margin-bottom: 7px;
    }
`;

const SearchInput = styled.input`
    border: none;
    background: ${colors.cinzaClaro};
    color: ${colors.cinzaEscuro}

    font-family: 'MontserratRegular';
    line-height: 35px;
    min-height: 45px;
    font-size: 12px;

    margin-top: 6px;
    margin-bottom: 6px;

    margin-right: 6px;

    border-radius: 0px 5px 5px 0px;
    padding-left: 20px;

    display: flex;
    flex: 1;

    &:focus {
        background-color: ${colors.cinzaClaroFundo}; 
        border: solid 2px ${colors.cinzaClaro};
        outline: none;
    }

    @media  ${device.mobileS} {
        line-height: 42px;
        margin-right: 20px;
    }

    @media  ${device.mobileM} {
        line-height: 50px;
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 16px;
    }
`;

const TextInput = styled.input`
    border: none;
    background: ${colors.rosaClaro};
    color: ${colors.cinzaEscuro}

    font-family: 'MontserratRegular';
    line-height: 35px;


    margin-top: 6px;
    margin-bottom: 6px;

    margin-left: 7px;
    margin-right: 7px;

    border-radius: 2px;
    padding-left: 10px;
    flex:1;

    &:focus {
        background-color: ${colors.rosaMaisClaro}; 
        border: solid 2px ${colors.rosaClaro};
        outline: none;
    }

    @media  ${device.mobileS} {
        line-height: 40px;

        margin-left: 30px;
        margin-right: 30px;
    }

    @media  ${device.mobileM} {
        line-height: 42px;

        margin-top: 7px;
        margin-bottom: 7px;

        margin-left: 35px;
        margin-right: 35px;
    }
`;

const TitleText = styled.p`
    text-align: center;

    font-family: 'MontserratRegular';
    font-size: 16px;

    margin: 0;
    margin-top: 1px;
    margint-bottom: 1px;
    padding: 0;

    color: ${colors.cinza}

    @media  ${device.mobileS} {
        font-size: 20px;
        margin-top: 2px;
        margint-bottom: 4px;
    }

    @media  ${device.mobileM} {
        font-size: 26px;
        margin-top: 3px;
        margin-bottom: 8px;
    }
`;

const BigText = styled.p`
    text-align: center;
    
    font-size: 12px;
    font-family: 'MontserratRegular';

    margin: 0;
    margin-top: 1px;
    margint-bottom: 1px;
    padding: 0;
    color: ${props => props.claro ? colors.branco : props.success ? colors.success : colors.cinza}
    

    @media  ${device.mobileS} {
        font-size: 14px;
        margin-top: 2px;
        margint-bottom: 2px;
    }

    @media  ${device.mobileM} {
        font-size: 20px;
        margin-top: 3px;
        margint-bottom: 3px;
    }
`;

const SmallText = styled.p`
text-align: center;

font-size: 8px;
font-family: 'MontserratRegular';

margin: 0;
margin-top: 10px;
margint-bottom: 1px;
padding: 0;
color: ${colors.cinza}

@media  ${device.mobileS} {
    font-size: 12px;
    margin-top: 2px;
    margint-bottom: 2px;
}

@media  ${device.mobileM} {
    font-size: 16px;
    margin-top: 3px;
    margint-bottom: 3px;
}
`;

const Button = styled.button`
    color: ${colors.cinzaClaro};
    background: ${colors.rosaForte};

    font-size: 12px;
    line-height: 35px;
    font-family: 'MontserratLight';
    
    border: none;
    border-radius: 2px;

    padding-left: 10px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 6px;
    margin-bottom: 6px;

    flex: 1;
    text-align: left;

    &:active {
        background-color: ${colors.palletaRomanceSombras.rosa1};
        color: ${colors.branco};
    }

    @media  ${device.mobileS} {

        font-size: 14px;
        line-height: 40px;

        margin-left: 30px;
        margin-right: 30px;
    }

    @media  ${device.mobileM} {
        line-height: 42px;

        margin-top: 7px;
        margin-bottom: 7px;

        margin-left: 35px;
        margin-right: 35px;
    }
`;

const PrimaryButton = styled.button`
    color: ${ colors.cinzaClaro};
    background: ${props => props.inverter ? 'none' : colors.rosaForte};

    font-family: 'MontserratLight';
    line-height: 25px;
    word-spacing: 1px;
    letter-spacing: .6px;
    text-align: center;

    flex: 1;
    align-self: center;

    padding: 8px 10px;

    border: ${props => props.inverter ? 'solid 2px ' + colors.rosaForte : 'solid 2px ' + colors.rosaForte};
    border-radius: 4px;

    margin-right: 20px;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 6px;

    &:active {
        background-color: ${colors.palletaRomanceSombras.rosa1};
        color: ${colors.branco};
    }

    @media  ${device.mobileL} {

        line-height: 30px;
        margin-left: 30px;
        margin-right: 30px;
    }
`;

const ButtonText = styled.p`
    text-align: center;
    
    font-size: 14px;
    font-family: 'MontserratLight';

    padding: 0px;
    margin: 2px;

    margin-top: 1px;
    margint-bottom: 1px;
    color: ${props => props.claro ? colors.branco : props.rosa ? colors.rosaForte : colors.cinza}
    

    @media  ${device.mobileM} {
        white-space: nowrap;
        white-space: pre;

        margin: 5px;

        font-size: 16px;
        margin-top: 2px;
        margint-bottom: 2px;
    }
`;

export {
    SearchInput,
    TextInput,
    TitleText,
    BigText,
    SmallText,
    Button,
    ListItem,
    ListContainer,
    SearchInputIcon,
    TelaFotoContainer,
    CameraWrapper,
    MarkerSuperior,
    MarkerInferior,
    LoadingSpinner,
    ResponseContainer,
    PrimaryButton,
    ButtonText,
    ImagemResponsiva,
    ViewContainer,
    FotoResponsiva,
    FotoFileContainer,
    MenuContainer,
};